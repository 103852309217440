<template>
  <div class="flex flex-wrap onboarding-wrap onboarding-bg pb-24">
    <div class="w-1/6"></div>
    <div class="w-2/4">
      <p class="text-white mt-44"><logo /></p>
      <h2 class="text-white font-bold head-title leading-none mt-6">Almost there!</h2>
      <p class="text-white text-2xl leading-none mt-16">Welcome {{ user_meta.given_name }}, we'd like to help you get started</p>
      <FormulateForm @submit="submitOnboarding()">
        <FormulateInput
          v-model="research_area"
          :options="settings.research_area"
          type="select"
          placeholder="Select an option"
          label="For what will you be using RadiTube?"
          class="mt-8 mb-6"
        />

        <div v-if="research_area === 'else'">
          <p class="text-white text-2xl leading-none my-8">
            We are still discovering all the potential use cases for RadiTube?
            We'd be super curious to hear what you'd like to use RadiTube for:
          </p>

          <FormulateInput
            type="textarea"
            v-model="alt_research_area"
            label="I'd like to use RadiTube for:"
            validation="max:50,length"
            :help="`Keep it under 50 characters. ${
              50 - alt_research_area.length
            } left.`"
          />
        </div>

        <FormulateInput
          v-model="research_place"
          :options="settings.workplace"
          type="select"
          placeholder="Select an option"
          label="Where do you work?"
        />

        <!-- <FormulateInput
          v-model="email_premission"
          type="checkbox"
          label="I'd like to be informed with updates and tips"
        /> -->
        <div class="w-full flex items-center pl-6 mt-6">
          <input 
            v-model="email_premission"
            id="email_premission" 
            name="email_premission"
            type="checkbox" 
            class="hidden checkboxGrey"
          >
          <label for="email_premission" class="labelGrey block cursor-pointer">
            <p class="text-base text-white ml-1">I'd like to be informed with updates and tips</p>
          </label>
        </div>

        <button 
          type="submit"
          class="font-monoDemi inline-block text-xs font-grey-darker-500 leadint-none purple-bg purple-light whitespace-nowrap rounded-2xl px-5 py-2 mr-4 mt-8"
        >
          Continue to RadiTube
        </button>

        <div class="w-full mt-4">
          <button 
            @click="answerNextTime"
            class="font-monoDemi inline-block text-xs font-grey-darker-500 leadint-none yellow-bg rounded-2xl px-5 py-2 mt-2"
          >
            I'll answer these questions next time.
          </button>
        </div>
        
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import settings from "../../settings.json";
import logo from '@/components/icons/logo'
import { mapGetters } from 'vuex';

export default {
  name: "Onboarding",
  components: {
    logo
  },
  data() {
    return {
      settings: settings,
      research_area: "",
      research_place: "",
      alt_research_area: "",
      email_premission: false,
    };
  },
  computed: {
    ...mapGetters({
      user_meta: 'general/user_meta'
    })
  },
  methods: {
    submitOnboarding() {
      this.$auth.getTokenSilently().then((res) => {
        console.log(res);

        // const { data } = this.$axios.post(
        //   `${process.env.VUE_APP_API_DOMAIN}/onboarding`,
        //   {
        //     research_area: this.research_area,
        //     workplace: this.research_place,
        //     alt_research_area: this.alt_research_area,
        //     email_premission: this.email_premission,
        //   },
        //   {
        //     headers: {
        //       Authorization: `Bearer ${res}`,
        //     },
        //   }
        // );

        // console.log(data);
      });

      // axios post to endpoint
      // set onboarding to false
    },
    answerNextTime(){

    }
  },
};
</script>

<style scoped>
.onboarding-wrap{
  min-height: calc(100vh - 3rem);
}
.labelGrey:before{
  top: .25rem;
}
</style>