<template>
  <Onboarding />
</template>

<script>
  import Onboarding from '@/components/Onboarding'

  export default {
    components: {
      Onboarding
    }
  }
</script>

<style lang="scss" scoped>

</style>